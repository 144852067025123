import { Link as FkLink } from '@flipgrid/flipkit';
import { Link as RemixLink, NavLink as RemixNavLink } from '@remix-run/react';
import { forwardRef } from 'react';

import type { LinkProps as FkLinkProps } from '@flipgrid/flipkit';
import type { LinkProps as RemixLinkProps } from '@remix-run/react';

type LinkProps = FkLinkProps & RemixLinkProps & { as?: 'link' | 'nav' };

const Link = forwardRef<HTMLLinkElement, LinkProps>(({ as = 'link', children, ...rest }, ref) => {
  return (
    <FkLink as={as === 'nav' ? RemixNavLink : RemixLink} ref={ref} {...rest}>
      {children || null}
    </FkLink>
  );
});

export default Link;
